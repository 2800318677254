.landing-container .landing-wrapper button.sign-in, .landing-container .landing-wrapper button.sign-in:active, .landing-container .landing-wrapper button.sign-in:hover, .landing-container .landing-wrapper button.sign-in:focus, .landing-container .landing-wrapper button.sign-in:visited, .landing-container .landing-wrapper button.sign-in:active:focus, .landing-container .landing-wrapper button.register, .landing-container .landing-wrapper button.register:active, .landing-container .landing-wrapper button.register:hover, .landing-container .landing-wrapper button.register:focus, .landing-container .landing-wrapper button.register:visited, .landing-container .landing-wrapper button.register:active:focus {
    background-color: #ad7735 !important;
}
.btn-primary {
    border-color: #ad7735 !important;
    background-color: #ad7735 !important;
}
.sidebar, .sidebar .list-group a {
    background-color: #ad7735 !important;
}

.sidebar .list-group .list-group-item.active {
    background-color: #884e09 !important;
}
.card .card-header {
    background-color: #884e09 !important;
}
.button-color {
    border-color: #ad7735 !important;
    background-color: #ad7735 !important;
}
.menu-content .searchForm button.clear, .menu-content .searchForm button.clear:active, .menu-content .searchForm button.clear:hover, .menu-content .searchForm button.clear:focus, .menu-content .searchForm button.clear:visited, .menu-content .searchForm button.clear:active:focus {
    border-color: #ad7735 !important;
    color: #ad7735 !important;
}
.view-details, .clear, .clear:active,.edit-style {
    color: #ad7735 !important;
}
.pageNumbers.list-group .list-group-item, .btn-extract {
    border-color: #ad7735 !important;
}
/* .list-group-item.active, .list-group-item.active, .btn-extract {
    background-color: #ad7735 !important;
} */
.list-group-item.active, .btn-extract {
    background-color: #ad7735 !important;
}
.file-row {
    border-color: #ad7735 !important;
    background-color: #fff8e6 !important;
}
.btn-goback {
    background: #fff;
    border: 1px solid #ad7735 !important;
    color: #ad7735 !important;
}
.agentNotes-header {
    background: #884e09 !important;
    color: white;
    border: 1px solid #884e09 !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #ad7735 !important;
}
.drag-text {
    color: #ad7735 !important;
}
.plus-min .plus-min-box {
     border: 1px solid #ad7735 !important;
}
.sampling, .downloadLink {
    color: #ad7735 !important;
}
span.plus-min-box.plus:hover, span.plus-min-box.minus:hover {
    background: #ad7735 !important;
    color: #fff;
}
.clear, .clear:active {
    background: #FFFFFF !important;
    color: #ad7735 !important;
    border: 1px solid #ad7735 !important;
}
.add-user-header {
    background: #884e09 !important;
    color: white;
    border: 1px solid #884e09 !important;
}
.search-list-item:focus, .search-list-item:hover {
    color: #ffffff !important;
    background-color: #ad7735 !important;
}
.registration-form .padding-form button.register, .registration-form .padding-form button.register:active, .registration-form .padding-form button.register:hover, .registration-form .padding-form button.register:focus, .registration-form .padding-form button.register:visited, .registration-form .padding-form button.register:active:focus {
    background: #ad7735 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.tab-content .nav-tabs .nav-item.show .nav-link, .tab-content .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #ad7735 !important;
    border-color: #ad7735 #ad7735 #fff;
    border: 1px solid #ad7735 !important;
}
.tab-content .nav-tabs {
    border-bottom: 3px solid #ad7735 !important;
}
.chart-operations button, .chart-operations button:hover, .chart-operations button:active {
    background: #fff !important;
    border: 1px solid #ad7735 !important;
    color: #ad7735 !important;
}
.searchForm .form-control:focus {
    border-color: #ad7735 !important;
}
.score-plus-minus .plus-min-box.plus {
    background: #ad7735 !important;
}
.alert-style {
    border: 1px dashed #ad7735 !important;
    background-color: #f7e9d9 !important;
}
.or-circle h5 {
    border: 1px solid #e2c7a9;
}
.samplingDataset-header {
    background: #884e09 !important;
}
.fileLayout .file-box {
    border: 1px solid #ad7735 !important;
    color: #ad7735 !important;
}
.team-form-button .text-btn-color, .team-form-button .text-btn-color:hover, .team-form-button .text-btn-color:active {
    background: #ad7735 !important;
}
.no-team-available-msg {
    background: #f7e9d9 0% 0% no-repeat padding-box !important;
    border: 1px dashed #ad7735 !important;
}
.no-team-available-msg span {
    color: #ad7735 !important;
}
.notify-style {
    color: #ad7735 !important;
}
.commentHistory {
    border-left: 3px solid #ad7735 !important;
}
.showCmt {
    color: #ad7735 !important;
}
/* .landing-container .landing-wrapper button.sign-in, .landing-container .landing-wrapper button.sign-in:active, .landing-container .landing-wrapper button.sign-in:hover, .landing-container .landing-wrapper button.sign-in:focus, .landing-container .landing-wrapper button.sign-in:visited, .landing-container .landing-wrapper button.sign-in:active:focus, .landing-container .landing-wrapper button.register, .landing-container .landing-wrapper button.register:active, .landing-container .landing-wrapper button.register:hover, .landing-container .landing-wrapper button.register:focus, .landing-container .landing-wrapper button.register:visited, .landing-container .landing-wrapper button.register:active:focus {
    background-color: #ad7735 !important;
} */
.showCmts {
    color: #ad7735 !important;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #884e09;
    border-radius: 10px; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #884e09; 
  }


  /* Loader Styles **/
  #loader svg {
    fill: #884e09;
    fill-opacity: 1 !important;
    stroke: #884e09;
}
#loader svg circle{
    stroke-opacity: 1 !important;
}
#loader svg path{
    stroke: #0063c3;
    fill-opacity: 1 !important;
}

.workflowBtn {
    background-color: #884e09;
    color: #fff!important;
}